import React from 'react'
import PropTypes from 'prop-types'

import './AboutSectionStyles.scss'

import { buildComponentsFromHtmlAst } from '../../utils/helpers'

const AboutSection = ({
  title,
  subtitle,
  image,
  useCircularImage,
  content,
  isAboutCreator,
  floatImageLeft
}) => {
  let imgClasses = useCircularImage ? "circular" : ""
  let sectionClasses = "about-page-section"
  if (isAboutCreator) {
    sectionClasses += " about-creator"
  }
  if (floatImageLeft) {
    sectionClasses += " image-on-left"
  }
  return (
    <section className={sectionClasses}>
      <div className="content-wrapper">
        <h2 className="mobile-only">{ title }</h2>
        { subtitle !== "dummy" &&
          <span className="mobile-only">{ subtitle }</span>
        }
        { image && image.description !== "dummy" &&
            <img className={imgClasses} src={image.file.url} alt={image.description} />
        }
        <div>
          <h2 className="nonmobile-only">{ title }</h2>
          { subtitle !== "dummy" &&
            <span className="nonmobile-only">{ subtitle }</span>
          }
          { content && buildComponentsFromHtmlAst(content.childMarkdownRemark.htmlAst.children) }
        </div>
        
      </div>
    </section>
  )
}

AboutSection.defaultProps = {
  subtitle: "dummy",
  image: null,
  useCircularImage: false,
  aboutCreator: false,
  floatImageLeft: false,
}

AboutSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  useCircularImage: PropTypes.bool,
  content: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      htmlAst: PropTypes.shape({
        children: PropTypes.array
      })
    })
  }),
  isAboutCreator: PropTypes.bool,
  floatImageLeft: PropTypes.bool,
}

export default AboutSection