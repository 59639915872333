import React from "react";

export const buildComponentsFromHtmlAst = (dataList, lvl=0) => {
  if (dataList && dataList.length === 1) {
    if (dataList[0].type === 'text' && dataList[0].value !== "\n") {
      return dataList[0].value;
    }
  }
  let result = [];
  for (let i = 0; i < dataList.length; i += 1) {
    let componentData = dataList[i];
    if (componentData.type === "element") {
      const DynamicTag = componentData.tagName;
      result.push(
        <DynamicTag key={`${componentData.type}-${i}`}>
          { buildComponentsFromHtmlAst(componentData.children, lvl + 1) }
        </DynamicTag>
      );
    } else if (componentData.type === 'text' && dataList[0].value !== "\n") {
      result.push(componentData.value)
    }
    
  }
  return result;
}


export const formatPhoneNumber = (phone) => {
  if (phone.length === 10) {
    return '(' + phone.substring(0,3) + ') ' + phone.substring(3,6) + '-' + phone.substring(6) 
  } if (phone.length === 11) {
    return '+' + phone[0] + ' (' + phone.substring(1,4) + ') ' + phone.substring(4,7) + '-' + phone.substring(7) 
  }
}