import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import SEO from '../components/seo'

import AboutSection from '../components/aboutSection/AboutSection'
import PageHeader from '../components/pageHeader/PageHeader'

const AboutPage = ({ data }) => {
  let sectionsCount = 0;
  const { 
    title, 
    navbar,
    seo,
    aboutCreatorSection,
    sections
  } = data.contentfulAboutPage
  return (
    <Layout navbarData={navbar}>
      <SEO {...seo} />
      <PageHeader title={title} />
      {
        <AboutSection floatImageLeft isAboutCreator {...aboutCreatorSection} />
      }
      {
        sections.map((sectionData, index) => {
          sectionsCount += 1
          if (sectionsCount % 2 === 0) {
            return <AboutSection floatImageLeft key={index} {...sectionData} />
          }
          return <AboutSection key={index} {...sectionData} />
        })
      }
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    contentfulAboutPage {
      title
      navbar {
        ...NavbarFragment
      }
      seo {
        ...SeoFragment
      }
      sections {
        title
        subtitle
        image {
          description
          file {
            url
          }
        }
        useCircularImage
        content {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
      aboutCreatorSection {
        title
        subtitle
        image {
          description
          file {
            url
          }
        }
        useCircularImage
        content {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  }
`

export default AboutPage
